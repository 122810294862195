<template>
  <div>
    <v-container fluid>
      <v-card class="pa-7">
        <v-card-title>
          <span color="primary" class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" :valid="valid">
            <v-row>
              <v-col md="4">
                <v-text-field
                  placeholder="رابط الفيديو"
                  label="رابط الفيديو"
                  v-model="resultForm.video_link"
                />
              </v-col>
              <v-col md="2">
                <v-file-input
                  v-model="attachment"
                  chips
                  label="مرفق"
                ></v-file-input>
              </v-col>

              <v-col md="2">
                <div
                  v-if="file"
                  class="image-area"
                  style="margin-bottom: 10px;"
                >
                  <img
                    :src="file.data.full_url"
                    v-if="file"
                    width="250px"
                    height="250px"
                  />
                  <!-- @click="openLink(img.data.full_url)" -->

                  <a
                    class="remove-image"
                    @click="removeimg(file.id, 'attachment')"
                    href="#"
                    style="display: inline;"
                    >&#215;</a
                  >
                </div>
              </v-col>

              <v-col md="2">
                <v-file-input
                  v-model="plane"
                  chips
                  label="الخطة العملي"
                ></v-file-input>
              </v-col>
              <v-col md="2">
                <div
                  v-if="file1"
                  class="image-area"
                  style="margin-bottom: 10px;"
                >
                  <img
                    :src="file1.data.full_url"
                    v-if="file1"
                    width="250px"
                    height="250px"
                  />
                  <!-- @click="openLink(img.data.full_url)" -->

                  <a
                    class="remove-image"
                    @click="removeimg(file1.id, 'plane')"
                    href="#"
                    style="display: inline;"
                    >&#215;</a
                  >
                </div>
              </v-col>
              <v-col md="12">
                <v-autocomplete
                  :loading="loadingValues"
                  v-model="value"
                  :items="values"
                  dense
                  chips
                  small-chips
                  multiple
                  label="النتائج"
                  item-text="value"
                ></v-autocomplete>
              </v-col>
              <v-col md="12">
                <quill-editor
                  v-model="resultForm.before_result"
                  ref="myQuillEditor"
                  :options="editorOption"
                  :rules="[
                    (v) =>
                      !!(v && v.length > 0) || 'please provide body content',
                  ]"
                />
              </v-col>
              <v-col md="12">
                <quill-editor
                  style="direction:ltr"
                  v-model="resultForm.enbefore_result"
                  ref="myQuillEditor"
                  :options="editorOption"
                  :rules="[
                    (v) =>
                      !!(v && v.length > 0) || 'please provide body content',
                  ]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="10"> </v-col>
              <v-col cols="12" md="2">
                <v-btn color="success" @click="submit">
                  إرسال
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-data-table
                  :headers="resultHeaders"
                  :items="results"
                  item-key="id"
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar flat class="mb-7">
                      <!-- delete item -->
                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title class="headline"
                            >Are you sure you want to delete this
                            user?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeDelete"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="deleteItemConfirm()"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.color="{ item }">
                    <div
                      v-if="item.color"
                      v-bind:style="{
                        borderRadius: '25px',
                        width: '15px',
                        height: '15px',
                        background: item.color,
                      }"
                    />
                  </template>
                  <template v-slot:item.color1="{ item }">
                    <div
                      v-if="item.color1"
                      v-bind:style="{
                        borderRadius: '25px',
                        width: '15px',
                        height: '15px',
                        background: item.color1,
                      }"
                    />
                  </template>

                  <template
                    v-slot:item.actions="{ item }"
                    style="textAlign:right !important"
                  >
                    <!-- <v-btn to="posts/add" text class="pa-0"> -->
                    <v-icon
                      color="primary"
                      small
                      class="mr-2"
                      @click="editResult(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <!-- </v-btn> -->

                    <v-icon
                      color="error"
                      small
                      class="mr-2"
                      @click="deleteResult(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <!-- <v-card-actions>
        <v-spacer />
        <v-btn @click="createCourse" color="primary">
          <v-icon>mdi-send</v-icon>
        </v-btn>
      </v-card-actions> -->
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error darken-1" text @click="close">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text @click="submit">
          Submit
        </v-btn> 
        </v-card-actions> -->
      </v-card>
      <v-overlay :value="submitted">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <!-- snackbar -->
      <v-snackbar
        v-model="snackbar"
        absolute
        top
        :multi-line="true"
        :color="snackbarColor"
      >
        {{ snackbarMsg }}
        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["formTitle", "dialog", "course_id"],
  data() {
    return {
      snackbar: false,
      snackbarMsg: null,
      snackbarColor: "success",
      snackbarMsg: null,
      snackbarColor: "",
      loadingValues: false,
      loadingGroups: false,
      snackbar: false,
      valid: false,
      submitted: false,
      loading: false,
      dialogDelete: false,
      group_id: null,
      results: [],
      values: [],
      groups: [],
      value: null,
      record: null,
      resultHeaders: [
        {
          text: "النتيجة",
          align: "start",
          sortable: false,
          value: "result",
        },
        { text: "المرفق", value: "color" },
        { text: "الخطة", value: "color1" },

        { text: "", value: "actions", sortable: false },
      ],
      error: "",
      resultForm: {
        id: null,
        course_id: null,
        result: null,
        attachment: null,
        plane: null,
        video_link: null,
        enbefore_result: null,
        before_result: null,
      },
      editorOption: {
        // some quill options
      },
      plane: null,
      attachment: null,
      file: null,
      file1: null,
      record: null,
      textRules: [(v) => !!v || "field is required"],

      // =================== for updating users ===================
      initialPayload: {},
    };
  },
  methods: {
    reset() {
      Object.keys(this.resultForm).forEach((element) => {
        this.resultForm[element] = null;
      });
    },
    async removeimg(id, text) {
      const { data } = await ApiService.delete(`files/${id}`);
      this.file = null;
      this.resultForm[text] = null;
      this.submit();
    },
    deleteResult(record) {
      this.record = record;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      try {
        this.loading = true;
        await ApiService.delete(`items/result_options/${this.record.id}`);
        this.loadData();
        this.loading = false;
        this.closeDelete();

        alert("Product deleted successfully");
        this.loadData();
      } catch (error) {
        console.log(error);
      }
    },
    closeDelete() {
      this.record = null;
      this.dialogDelete = false;
    },
    async editResult(item) {
      this.reset();
      Object.keys(this.resultForm).forEach((element) => {
        if (item[element]) {
          if (typeof item[element] == "object") {
            this.resultForm[element] = item[element].id;
          } else {
            this.resultForm[element] = item[element];
          }
        }
      });
      // if (this.resultForm.attachment) {
      //   const { data } = await ApiService.getFile(
      //     "files",
      //     this.resultForm.attachment
      //   );
      //   this.file = data.data;
      // }
      // if (this.resultForm.plane) {
      //   const { data1 } = await ApiService.getFile(
      //     "files",
      //     this.resultForm.plane
      //   );
      //   this.file1 = data1.data;
      // }
      this.value = item.result.split("-");
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.submitted = true;
          let formData = new FormData();
          if (this.attachment) {
            formData.append("data", this.attachment);
            const fileReq = await ApiService.postFile(
              "files",
              formData,
              0,
              null
            );
            this.resultForm["attachment"] = fileReq.data.data.id;
          }
          if (this.plane) {
            formData.append("data", this.plane);
            const fileReq1 = await ApiService.postFile(
              "files",
              formData,
              0,
              null
            );
            this.resultForm["plane"] = fileReq1.data.data.id;
          }
          let result;
          this.value.sort();
          this.value.forEach((element) => {
            if (result) {
              result = result + "-" + element;
            } else {
              result = element;
            }
          });
          this.resultForm["result"] = result;
          if (this.resultForm.id) {
            const resultReq = await ApiService.update(
              "items/result_options",
              this.resultForm.id,
              this.resultForm
            );
            console.log("resultReq : ", resultReq);
          } else {
            this.resultForm.course_id = this.$route.params.id;
            console.log(this.resultForm);
            const resultReq = await ApiService.post(
              "items/result_options",
              this.resultForm
            );
          }
          this.submitted = false;
          this.loadData();
          // this.$emit("formSubmitted", "categories ok");
          const msg = this.record
            ? "record updated successfully"
            : "record inserted successfully";
          this.snackbarMsg = msg;
          this.snackbar = true;
        } else {
          this.snackbar = true;
          this.error = "please fill all required fields";
        }
      } catch (error) {
        if (error.message !== "") {
          this.error = error;
        } else {
          this.error = "sorry, something went wrong";
        }
        this.snackbar = true;
        console.log(error);
        this.submitted = false;
      }
    },
    close() {
      this.$emit("closeDialog");
    },
    async loadData() {
      this.reset();
      this.loading = true;
      const resultReq = await ApiService.get(
        `items/result_options?fields=id,result,enbefore_result,before_result,video_link,attachment,plane,course_id&filter[course_id]=${this.$route.params.id}`
      );

      this.results = resultReq.data.data;
      this.results.forEach((element) => {
        element.attachment
          ? (element.color = "green")
          : (element.color = "red");
        element.plane ? (element.color1 = "green") : (element.color1 = "red");
      });
    },
    async getvalues() {
      try {
        this.loadingValues = true;
        const { data } = await ApiService.get(
          `items/groups_answers_value?fields=id,value&filter[group_id.course_id]=${this.$route.params.id}`
        );
        this.values = data.data;
        this.loadingValues = false;
      } catch (error) {
        console.log(error);
      }
    },
  },

  async mounted() {
    this.getvalues();
    this.loadData();
  },
};
</script>

<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
